<template>
  <div>
    <b-row>
      <b-col cols="2">
        <b-card no-body class="mb-0" style="height: 57rem; overflow-y: auto">
          <div class="d-flex align-items-center justify-content-around my-50">
            <b-button
              v-b-tooltip.hover.right="lang('t_queue')"
              v-b-tooltip.hover.v-secondary
              @click="
                category = 'queue';
                selectedFilters = [];
                showAll = true;
              "
              class="px-50 py-50"
              :variant="category == 'queue' ? 'flat-primary' : 'flat-secondary'"
            >
              <i class="bi bi-diagram-3 font-medium-3"></i>
            </b-button>
            <b-button
              v-b-tooltip.hover.right="lang('t_project')"
              v-b-tooltip.hover.v-secondary
              @click="
                category = 'project';
                selectedFilters = [];
                showAll = true;
              "
              class="px-50 py-50"
              :variant="
                category == 'project' ? 'flat-primary' : 'flat-secondary'
              "
            >
              <i class="bi bi-briefcase font-medium-3"></i>
            </b-button>
            <b-button
              v-b-tooltip.hover.right="lang('t_line')"
              v-b-tooltip.hover.v-secondary
              @click="
                category = 'line';
                selectedFilters = [];
                showAll = true;
              "
              class="px-50 py-50"
              :variant="category == 'line' ? 'flat-primary' : 'flat-secondary'"
            >
              <i class="bi bi-telephone font-medium-3"></i>
            </b-button>
          </div>
          <b-list-group>
            <b-list-group-item
              :active="showAll"
              class="border-left-0 border-right-0 border-top-0"
              button
              @click="
                showAll = true;
                selectedFilters = [];
              "
              >{{ lang("t_all") }}</b-list-group-item
            >
            <b-list-group-item
              @click="itemSelected(item.internal_name)"
              :active="selectedFilters.includes(item.internal_name)"
              v-for="(item, i) in getCategoryData()"
              :key="i"
              class="border-left-0 border-right-0"
              button
              >{{ item.display_name }}</b-list-group-item
            >
          </b-list-group>
        </b-card>
      </b-col>
      <b-col cols="10">
        <b-card no-body class="mb-0" style="height: 57rem; overflow-y: auto">
          <b-table
            small
            :filter="searchQuery"
            hover
            :fields="fields"
            :items="FilteredRecords"
            responsive
            :sortBy="'StartTime'"
            show-empty
            :empty-text="lang('t_noRecord')"
            :sort-desc.sync="sortDesc"
            :busy="records.length == 0"
          >
            <template #cell(Agent)="data">
              <div v-if="data.value" class="d-flex align-items-center">
                <b-avatar
                  class="mr-50"
                  variant="light-secondary"
                  :text="`${data.value
                    .split('.')[0][0]
                    .toUpperCase()}${data.value
                    .split('.')
                    [data.value.split('.').length - 1][0].toUpperCase()}`"
                >
                </b-avatar>
                <span>
                  {{ data.value }}
                </span>
              </div>
            </template>
            <template #cell(StartTime)="data">
              {{ msToTimeString((new Date().getTime() - data.value) / 1000) }}
            </template>
            <template #cell(Queue)="data">
              <b-badge variant="light-primary">
                {{
                  queues.find((e) => e.internal_name == data.value)
                    ? queues.find((e) => e.internal_name == data.value)
                        .display_name
                    : data.value
                }}
              </b-badge>
            </template>
            <template #cell(Project)="data">
              {{
                projects.find((e) => e.internal_name == data.value)
                  ? projects.find((e) => e.internal_name == data.value)
                      .display_name
                  : data.value
              }}
            </template>
            <template #cell(IVRName)="data">
              {{
                ivr_list.find((e) => e.internal_name == data.value)
                  ? ivr_list.find((e) => e.internal_name == data.value)
                      .display_name
                  : data.value
              }}
            </template>
            <template #cell(Direction)="data">
              <div class="d-flex align-items-center">
                <b-icon
                  icon="telephone-inbound"
                  v-if="data.value == 'inbound'"
                  v-b-tooltip.hover.bottom="data.value"
                  v-b-tooltip.hover.v-secondary
                ></b-icon>
                <b-icon
                  icon="telephone-outbound"
                  v-if="data.value == 'outbound'"
                  v-b-tooltip.hover.bottom="data.value"
                  v-b-tooltip.hover.v-secondary
                ></b-icon>
                <b-icon
                  icon="telephone-outbound"
                  v-if="data.value == 'dialer'"
                  v-b-tooltip.hover.bottom="data.value"
                  v-b-tooltip.hover.v-secondary
                ></b-icon>
                <span class="ml-50">{{ data.value }}</span>
              </div>
            </template>
          </b-table>
        </b-card>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import {
  BListGroup, BListGroupItem,
  BProgress,
  VBTooltip,
  BTable,
  BButtonGroup,
  BFormInput,
  BButton,
  BBadge,
  BContainer,
  BRow,
  BCol,
  BCard,
  BCardText,
  BCardTitle,
  BCardSubTitle,
  BCardBody,
  BAvatar,
  BCardHeader
} from "bootstrap-vue";
import vSelect from "vue-select";

export default {
  components: {
    BListGroup, BListGroupItem,
    BAvatar,
    BCardHeader,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
    BProgress,
    VBTooltip,
    BTable,
    BButtonGroup,
    BFormInput,
    BContainer,
    BRow,
    BCol,
    BCard,
    BCardText,
    BBadge,
    vSelect,
    BButton,
  },
  data() {
    return {
      lang: _l(window.localStorage.getItem("language") || "tr"),
      category: 'queue',
      showAll: true,
      sortDesc: true,
      selectedFilters: [],
      projects: [],
      queues: [],
      lines: [],
      ivr_list: [],
      full_screen: false,
      fields: [
        { key: "Agent", label: globalThis._lang("t_agent"), sortable: true },

        {
          key: "Direction",
          label: globalThis._lang("t_direction"),
          sortable: true,
        },
        {
          key: "StartTime",
          label: globalThis._lang("t_duration"),
          sortable: true,
        },
        {
          key: "RemoteNumber",
          label: globalThis._lang("t_phone"),
          sortable: true,
        },
        {
          key: "State",
          label: globalThis._lang("t_status"),
          sortable: true,
        },

        {
          key: "Project",
          label: globalThis._lang("t_project"),
          sortable: true,
        },
        {
          key: "Queue",
          label: globalThis._lang("t_queue"),
          sortable: true,
        },
        { key: "IVRName", label: globalThis._lang("t_ivr"), sortable: true },
        { key: "LocalNumber", label: globalThis._lang("t_line"), sortable: true },

      ],
      records: [],
      searchQuery: "",
      interval: null,
    };
  },
  directives: {
    "b-tooltip": VBTooltip,
  },
  methods: {
    getCategoryData() {
      if (this.category == 'queue') {
        return this.queues;
      }
      if (this.category == 'project') {
        return this.projects;

      }
      if (this.category == 'line') {
        return this.lines;

      }
    },
    itemSelected(item) {
      if (this.selectedFilters.includes(item)) {
        this.selectedFilters = this.selectedFilters.filter(e => e != item);
      } else {
        this.selectedFilters.push(item);
      }
      this.showAll = false;
      if (this.selectedFilters.length == 0) {
        this.showAll = true;
      }
    },
    msToTimeString(ms) {
      let seconds = Math.floor((ms) % 60);
      let minutes = Math.floor(ms / 60);

      seconds = seconds < 10 ? '0' + seconds : seconds;
      minutes = minutes < 10 ? '0' + minutes : minutes;

      return `${minutes}:${seconds}`;
    },

    GetIVR: async function () {
      var response = (await this.$http_in.get(`report/v1/Ivr`)).data;

      this.ivr_list = response.filter(e => e.type != 'transfer');
    },
    GetCurrentCallState: async function () {
      var response = (await this.$http_in.get(`qmgr/v1/CurrentCallState`)).data;
      // {
      //     "RemoteNumber": "5526350190",
      //     "StartTime": 1709634937711,
      //     "Queue": "personal:burak.ersever@voyce",
      //     "IVRName": "",
      //     "LocalNumber": "02129445779",
      //     "Agent": "burak.ersever",
      //     "Project": "voyce_project_voyce_project",
      //     "State": "Accepted"
      // }
      this.records = response;
    },
    GetLines: async function () {
      var response = (await this.$http_in.get(`report/v1/Line`)).data;
      this.lines = response.filter(el => el.caller_number.length > 9 && el.caller_number != '{{RemoteNumber}}').map(e => ({ "internal_name": e.caller_number, "display_name": e.caller_number }));
    },
  },
  computed: {
    FilteredRecords() {
      if (this.showAll) {
        return this.records;
      } else {
        if (this.category == 'queue') {
          return this.records.filter(e => this.selectedFilters.includes(e.Queue));
        }
        else if (this.category == 'project') {
          return this.records.filter(e => this.selectedFilters.includes(e.Project));
        }
        else if (this.category == 'line') {
          return this.records.filter(e => this.selectedFilters.includes(e.LocalNumber));
        }
        else {
          return this.records;

        }
      }

    }
  },
  mounted: async function () {
    let _self = this;
    this.queues = globalThis.queues;
    this.projects = globalThis.projects;
    await this.GetLines();
    await this.GetIVR();
    await this.GetCurrentCallState();


    this.interval = setInterval(() => {
      _self.GetCurrentCallState();
    }, 2000);
  },
  beforeDestroy() {
    clearInterval(this.interval);
  },
};
</script>

<style>
</style>
